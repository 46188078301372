<template>
  <div class="container-fluid">
    <div class="row row-no-gutters">
      <div class="col-xs-2">
        
      </div>
    </div>
  </div>
</template>
<script>
import { LoginService } from "@/services";

export default {
  name: "Logout",
  components: {},
  data() {
    return {
      
    };
  },
  created() {
    LoginService.logOut();
    this.$root.atualizarDadosHeader();
    this.$router.push('home');
  },
  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>